import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We all heard or know a story about an Afro-American people getting bullied or treated differently because of their skin color, but have you heard a white person getting treated differently? Well, that happened to me, and I am going to tell you about it.`}</p>
    <p>{`My name is Jack, I am from the U.S, long story short my family and I had to move to Tanzania. I was 12 years old when we came there. I knew that people there were people of color, and it kinda scared me because I would be an oddball. The moment we arrived there I already felt that weird tension, like everybody, was staring at us, and I could feel them wondering what we are doing here. The looks were killing me. When we arrived at our apartment it was much smaller than our house in the U.S. I didn’t go outside to play. I just stayed home and kept thinking about how the school will be.`}</p>
    <p>{`The new year came and kids were preparing for a new school year. Yes, in Tanzania school starts in January. When my mom was driving me to my 1st day of school I instantly regretted not going outside and meeting people. If I did I could have at least known someone but now, I will be all alone. My mom walked me to class since it was my 1st time. My heart was beating like I was about to walk into a class full of lions. When my mom saw my class teacher she wished me luck and went to work. I slowly walked in though I wanted to run away, and there they were, staring at me. I didn’t know what to do so I just stood there quietly, after what felt like forever the teacher introduced me to the class and said: “Welcome back everybody, this is our new student, he is from the U.S”.  The teacher pointed to an empty seat and slowly went towards it. While I was walking I felt the tense atmosphere I could feel them staring at me.`}</p>
    <p>{`The school finished after what felt like a decade, I headed to the main gate, where my mom was supposed to be waiting for me. When I got there she wasn’t there, so I just waited. While I was waiting a group of boys approached me and one of them pushed me with his shoulder.  I fell on snow and the boy just said “sorry, accident” with an American accent. I knew it wasn’t an accident so I just stood up and replied “its ok” with a Tanzania accent. I knew the boy got frustrated but he just left. Throughout the school year, I didn’t find a good friend, not because I didn’t want to but because no one else wanted to. Fortunately, this didn’t last long as in 1 year we moved back to the U.S.`}</p>
    <p>{`50 years have passed, my whole life has passed and I still remember the looks they gave me. I am 62 now and that year still is the most horrible and the most important year in my life. Every time I see someone being racist I stand up to them. It was the most complicated and hardest moment of my life, no person should ever experience such emotional torture. Everyone should be included and feel safe in the community they are in.  After all, according to science, we all came from the same place in Africa some of us just happened to move and that’s the only difference. We are all human and every race is beautiful in its own way, every life is beautiful in its own way. We need to respect and admire the differences. Now I am asking you to spread this message as I am now, and let’s hope in the future there will be no place for racism.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      